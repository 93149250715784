import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Spray Painting",
  "description": "For painting the surfaces of a large variety of materials.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["IMD-Lamination", "Powder-Coating"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Workpieces that have gone through all the manufacturing processes are eligible for painting. To establish an even painted surface, the workpiece must have a smooth surface: sanding must be done before to remove the roughness.`}</p>
    <p>{`NexPCB provides surface painting for a range of materials. These include plastics, bamboo, wood, and metal. The painting process can be sped up by using the spray painting process.`}</p>
    <p>{`Spray painting, like its name suggests, is done by using a tool to evenly spray paint across a workpiece's surface to provide a faster painting process with more even coverage than regular painting, especially for complex-shaped surfaces.`}</p>
    <p>{`It's possible to spray paint a product in any color visible to the human eye. However, due to the human eye's sensitivity towards purple, it's a hard color to achieve. White is also a difficult color to achieve in the presence of dust and other surface impurities that may be present at the time of spraying.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      